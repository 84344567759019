import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import homeLogo from "../../Assets/home-main.svg";
import Particle from "../Particle";
import Home2 from "./Home2";
import Profile from "../Pdfgenarot/Profile"
import Button from "react-bootstrap/Button";
import imge1 from "../../Assets/Imge1.jpg";
import imge2 from "../../Assets/Imge2.jpg";
import imge3 from "../../Assets/Imge3.jpg";
import imge4 from "../../Assets/Imge4.jpg";
import Nav from "react-bootstrap/Nav";
import Type from "./Type";
import Form from "../Form/form"
import { Link } from "react-router-dom";
import TemplateEditor from "../../components/Template/Templates"
function Home() {
  const [Cropeiagmefile, setCropeimgefile] = useState(null);
  const [showTemlate, setShotemlpate] = useState(false)

  const [Fakedata, setFormData] = useState({

    Previewsssss: [
      { key: "Name", value: "NIrakar", editable: false },
      { key: "Birthday", value: "10/09/1997", editable: false },
      { key: "Color", value: "Fair", editable: false }
    ],
    Previe: [
      { key: "fatherName", value: "Fadfafaf", editable: true },
      { key: "MotherName", value: "dfgasdfa", editable: true }
    ]
  });
  const transformData = (data) => {
    const transformedData = {};

    Object.keys(data).forEach((sectionKey) => {
      transformedData[sectionKey] = {};
      data[sectionKey].forEach((field) => {
        transformedData[sectionKey][field.key] = field.value;
      });
    });

    return transformedData;
  };
  const updateData = (sectionKey, index, value) => {
    const updatedSection = [...Fakedata[sectionKey]];
    updatedSection[index].value = value;
    setFormData((prevState) => ({
      ...prevState,
      [sectionKey]: updatedSection
    }));
  };


  const addField = (sectionKey) => {
    const newField = { key: "Enter Filed Name", value: "Enter Filed Value", editable: true };
    setFormData((prevState) => ({
      ...prevState,
      [sectionKey]: [...prevState[sectionKey], newField]
    }));
  };

  const handleKeyChange = (sectionKey, index, newKey) => {
    const updatedSection = [...Fakedata[sectionKey]];
    updatedSection[index].key = newKey;
    setFormData((prevState) => ({
      ...prevState,
      [sectionKey]: updatedSection
    }));
  };

  const deleteField = (sectionKey, index) => {
    const updatedSection = Fakedata[sectionKey].filter((_, i) => i !== index);
    setFormData((prevState) => ({
      ...prevState,
      [sectionKey]: updatedSection
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const transformedData = transformData(Fakedata);
    localStorage.setItem('myData', JSON.stringify(transformedData));

    console.log("Transformed Data:", transformedData);
    setShotemlpate(true)
  };

  return (
    <>
      {!showTemlate && <div>


        <section>
          <div>
            <div className="mainBox">
              <div className="Bnner">
                <div className="LAble">
                  <h1 className="FontSize">Marriage Biodeta</h1>
                </div>

                <div className="Ftame1">
                  <img style={{ objectFit: "contain" }} src={imge4} />
                </div>
                <div className="Frame2">
                  <img style={{ objectFit: "contain" }} src={imge2} />
                </div>
                <div className="Fmare3">
                  <img style={{ objectFit: "contain" }} src={imge1} />
                </div>
                <div className="Eleveret">
                  <h3 style={{ color: "black", fontWeight: "500" }}>
                    Elevate Your Biodata
                    <br />
                    with Premium
                    <br />
                    Designs
                  </h3>
                </div>
              </div>
              <div>
                <div>
                  <h2 className="Text P30 Font2">
                    Your Love Story
                    <br />
                    Deserves the Finest
                  </h2>
                </div>

                <div className="P30">
                  <h3 className="Text FotnWe">
                    Craft your perfect marriage biodata effortlessly! With just three
                    clicks, access our user-friendly interface for fully customizable,
                    designed biodata formats. Experience the premium feel as you
                    choose your template.
                  </h3>
                </div>
              </div>
              <div
                style={{
                  marginTop: "56px",
                  backgroundColor: "#F9F1E5",
                  marginBottom: "40px",
                }}
              >
                <h3 className="Text P40 Font2">
                  Step into Style
                  <br />
                  Transform Your Biodata with Our Premium Designs
                </h3>

                <div>
                  <div className="Crolse">
                    <div>
                      <img style={{ objectFit: "contain" }} src={imge1} />
                    </div>
                    <div>
                      <img style={{ objectFit: "contain" }} src={imge2} />
                    </div>
                    <div>
                      <img style={{ objectFit: "contain" }} src={imge4} />
                    </div>
                  </div>
                  <div className="Text Button">
                    <button className="Buttonstyle">Creat Yout Bioata</button>
                  </div>
                </div>
              </div>

              <div>
                <h2>Editable Data</h2>
                <Profile setCropeimgefile={setCropeimgefile} />

              </div>
            </div>
          </div>


          <h2>Editable Data</h2>
          <form onSubmit={handleSubmit}>
            {Object.keys(Fakedata).map((sectionKey) => (
              <div className="inpiutdipaybox" key={sectionKey}>
                <h3>{sectionKey}</h3>
                {Fakedata[sectionKey].map((field, index) => (
                  <div className="maininputBox" style={{ width: "100%" }} key={`${sectionKey}-${index}`}>

                    <div className="Boxinput">
                      <input
                        className="inputBox"
                        style={{ backgroundColor: "#e8eaef" }}
                        type="text"
                        value={field.key}
                        onChange={(e) => handleKeyChange(sectionKey, index, e.target.value)}
                        disabled={!field.editable}
                      />
                      <input
                        className="inputBox"
                        type="text"
                        style={{ backgroundColor: "rgb(255 255 255)" }}
                        value={field.value}
                        onChange={(e) => updateData(sectionKey, index, e.target.value)}
                      />

                    </div>
                    <div>
                      <button
                        style={{backgroundColor:"white",marginTop:"10px"}}
                        type="button"
                        onClick={() => deleteField(sectionKey, index)}
                        disabled={!field.editable}
                      >
                        <svg  xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi Buttonocn bi-x-circle" viewBox="0 0 16 16">
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                        </svg>
                      </button>
                    </div>
                  </div>
                ))}
                <button type="button" onClick={() => addField(sectionKey)}>
                  Add Field
                </button>
              </div>
            ))}
            <button type="submit">Submit</button>
          </form>

        </section>
      </div>}

      {showTemlate &&
        <div>
          <TemplateEditor Cropeiagmefile={Cropeiagmefile} />


        </div>
      }
    </>
  );
}

export default Home;
