import React, { useEffect, useState } from 'react';
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import { Document, Page } from 'react-pdf';
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import pdfFile from "../../Assets/PDF1.pdf";  // Import the PDF file from assets
import IMGAE1 from "../../Assets/Imge1.jpg"
import IMGAE2 from "../../Assets/Imge2.jpg"
import IMGAE3 from "../../Assets/Imge3.jpg"
import PDF1 from "../../Assets/PDF1.pdf"
import PDF2 from "../../Assets/PDF2.pdf"
import PDF3 from "../../Assets/PDF3.pdf"
import "react-image-crop/dist/ReactCrop.css";

const data = [
    { img: IMGAE1, pdf: PDF1 },
    { img: IMGAE2, pdf: PDF2 },
    { img: IMGAE3, pdf: PDF3 }
];

const TemplateViewer = ({ Cropeiagmefile }) => {
    const [PDF, setPdf] = useState(null);
    const [templateFile, setTemplateFile] = useState(pdfFile);
    const [userData, setUserData] = useState();
    const [numPages, setNumPages] = useState(null);

    useEffect(() => {
        const items = JSON.parse(localStorage.getItem('myData'));
        setUserData(items);
    }, []);

    const Previw = async (pdf) => {
        if (!pdf) return;
        setTemplateFile(pdf);

        const response = await fetch(pdf);
        const existingPdfBytes = await response.arrayBuffer();

        const pdfDoc = await PDFDocument.load(existingPdfBytes);
        const pages = pdfDoc.getPages();
        const firstPage = pages[0];
        const fontSize = 12;
        const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
        let Y = 700;

        Object.keys(userData).forEach(key1 => {
            const X = 60;
            const title = `${key1}`;
            firstPage.drawText(title, { x: X, y: Y, size: fontSize, font: helveticaFont, color: rgb(0, 0, 0) });
            Object.entries(userData[key1]).forEach(([key2, value]) => {
                Y -= 20;
                const keyText = `${key2} `;
                const xKey = X;
                const constan = ":";
                firstPage.drawText(keyText, { x: xKey, y: Y, size: fontSize, font: helveticaFont, color: rgb(0, 0, 0) });
                firstPage.drawText(constan, { x: 170, y: Y, size: fontSize, font: helveticaFont, color: rgb(0, 0, 0) });
                firstPage.drawText(value, { x: 200, y: Y, size: fontSize, font: helveticaFont, color: rgb(0, 0, 0) });
            });
            Y -= 50;
        });

        if (Cropeiagmefile) {
            const image = await pdfDoc.embedPng(Cropeiagmefile);
            const xPosition = 345;
            const yPosition = 530;
            const width = 150;
            const height = 200;
            firstPage.drawImage(image, {
                x: xPosition,
                y: yPosition,
                width: width,
                height: height,
            });
        }

        const pdfBytes = await pdfDoc.save();
        const blob = new Blob([pdfBytes], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        setPdf(url);
    };

    const Donload = () => {
        const a = document.createElement('a');
        a.href = PDF;
        a.download = 'populated_template.pdf';
        a.click();
        URL.revokeObjectURL(PDF);
    };

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    return (
        <div>
            <div className='PreviewTemplate'>
                {PDF && (
                    <Document
                        file={PDF}
                        onLoadSuccess={onDocumentLoadSuccess}
                        style={{ width: '200px', height: '400px', overflow: 'hidden' }} // Set the size here
                    >
                        {Array.from(new Array(numPages), (el, index) => (
                            <Page
                                key={`page_${index + 1}`}
                                pageNumber={index + 1}
                                width={344} // Set the width here
                                
                                // Set the height here
                            />
                        ))}
                    </Document>
                )}
            </div>
            <div className="temletbox">
                <div className="Buutonsage">
                    {data && data.map((x, index) => (
                        <div key={index} onClick={() => Previw(x.pdf)} className="Iagebox">
                            <img style={{ width: "100%", height: "100%" }} src={x.img} alt="Template Preview" />
                        </div>
                    ))}
                </div>
            </div>
            <div className="Buutonsage">
                <button>Bou now</button>
                <button>Close</button>
            </div>
            <button onClick={Donload}>Download Template as PDF</button>
        </div>
    );
};

export default TemplateViewer;
