import { useRef, useState } from "react";
import PencilIcon from "./PencilIcon";
import Modal from "./Modal";

const Profile = ({ setCropeimgefile }) => {
    const [imgSrc, setImgSrc] = useState("");
    const [error, setError] = useState("");
    const ASPECT_RATIO = 1;
    const MIN_DIMENSION = 150;
    const avatarUrl = useRef("");
    const fileInputRef = useRef(null);
const [miansrc,setMinsrc]= useState(null)
    const [modalOpen, setModalOpen] = useState(false);

    const updateAvatar = (imgSrc) => {
        setMinsrc(imgSrc)
        setCropeimgefile(imgSrc);
    };

    const remove = () => {
        console.log("remvoe")
        setMinsrc(null)
        setError("")
        avatarUrl.current = "";
    }



    const onSelectFile = (e) => {
        const file = e.target.files?.[0];
        if (!file) return;

        const reader = new FileReader();
        reader.addEventListener("load", () => {
            const imageElement = new Image();
            const imageUrl = reader.result?.toString() || "";
            imageElement.src = imageUrl;

            imageElement.addEventListener("load", (e) => {
                if (error) setError("");
                const { naturalWidth, naturalHeight } = e.currentTarget;
                if (naturalWidth < MIN_DIMENSION || naturalHeight < MIN_DIMENSION) {
                    setError("Image must be at least 150 x 150 pixels.");
                    return setImgSrc("");
                } else {
                    setImgSrc(imageUrl);
                    setModalOpen(true)
                }
            });
        });
        reader.readAsDataURL(file);
    };

    const handleClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    return (
        <div className="flex flex-col items-center pt-12">
                                    {error && <p className="text-red-400 text-xs">{error}</p>}

            <div className="Iamgeshoee">
                { miansrc ? (
                    <div>

                        <i onClick={remove} className="cincross">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" /></svg>

                        </i>
                        <div onClick={handleClick} className="iamgeboxsec">


                            <img
                                src={miansrc}
                                alt="Avatar"
                                className="iamgeboxsec"
                            />
                            <input
                                type="file"
                                style={{ display: 'none' }}
                                ref={fileInputRef}
                                onChange={onSelectFile}
                            />
                        </div>
                    </div>
                ) : (
                    <>

                        <div onClick={handleClick} className="iamgebox">

                            <i className="add-img-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <path d="M0 96C0 60.7 28.7 32 64 32l384 0c35.3 0 64 28.7 64 64l0 320c0 35.3-28.7 64-64 64L64 480c-35.3 0-64-28.7-64-64L0 96zM323.8 202.5c-4.5-6.6-11.9-10.5-19.8-10.5s-15.4 3.9-19.8 10.5l-87 127.6L170.7 297c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6l96 0 32 0 208 0c8.9 0 17.1-4.9 21.2-12.8s3.6-17.4-1.4-24.7l-120-176zM112 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z" />
                                </svg>
                            </i>
                            <p>Click here to add your photo (Up to 20MB in size)</p>
                            <input
                                type="file"
                                style={{ display: 'none' }}
                                ref={fileInputRef}
                                onChange={onSelectFile}
                            />
                        </div>
                    </>
                )}
            </div>

            <h2 className="text-white font-bold mt-6">Mack Aroney</h2>
            <p className="text-gray-500 text-xs mt-2"></p>
            {modalOpen && (
                <Modal
                    imgSrc={imgSrc}
                    setCropeimgefile={setCropeimgefile}
                    updateAvatar={updateAvatar}
                    closeModal={() => setModalOpen(false)}
                />
            )}
        </div>
    );
};

export default Profile;
